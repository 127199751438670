import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom';

const BannerCarousel = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5500,
    };

    return (
        <Slider {...settings}>
            <div className="hero-banner--inner-wrapper">
                <div className="row align-items-center">
                    <div className="col-12 col-lg-6">
                        <div className="hero-banner--content-wrap">
                            <p className="h1" style={{ color: '#031b47' }}>A Powerful software for Angel Networks and Syndicates to simplify startup investments.</p>
                            <p className="fs-1" style={{ fontSize: '24px', color: '#666' }}>Experience the power of software, Book a demo now!</p>
                        </div>
                        <div className="btn-wrapper">
                            <Link to="/book-a-demo" target="" className="main-btn">Book A Demo Now</Link>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="hero-banner--ft-img-wrapper">
                            <img width={1007} height={896} alt="Startup Smarter Hero" className="ft-img lazyload" src="assets/images/home-banner.svg" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="hero-banner--inner-wrapper">
                <div className="row align-items-center">
                    <div className="col-12 col-lg-6">
                        <div className="hero-banner--content-wrap">
                            <p className="h1" style={{ color: '#031b47' }}>A software to make your startup investment easier.</p>
                            <p className="fs-1" style={{ fontSize: '24px', color: '#666' }}>To Initiate your startup investment, Book a demo Now!</p>
                        </div>
                        <div className="btn-wrapper">
                            <Link to="/book-a-demo" target="" className="main-btn">Book A Demo Now</Link>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="hero-banner--ft-img-wrapper">
                            <img width={1007} height={896} alt="Startup Smarter Hero" className="ft-img lazyload" src="assets/images/startup-illustration.svg" />
                        </div>
                    </div>
                </div>
            </div>
        </Slider>
    );
};

export default BannerCarousel;
