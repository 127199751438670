import React from "react";
import Lottie from "lottie-react";
import lottieAnimation from "./lottie/launching-sson.json";
import LaunchingSoonCountDown from "./layouts/functions/LaunchingSoonCountDown";

let UnderConstruction = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center">
                    <img alt="Logo" src="assets/images/pvt_deal_logo.png" style={{ margin: '40px', maxWidth: '200px' }} />
                </div>
            </div>
            <div className="row">
                <div className="col-md-4">
                </div>
                <div className="col-md-4">
                    <LaunchingSoonCountDown />
                </div>
            </div>
            <div className="row">
                <div className="col-md-4">
                </div>
                <div className="col-md-4">
                    <Lottie animationData={lottieAnimation} loop={true} autoplay={true} />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <h4 className="text-center">Room under construction, opening soon for all to join in!</h4>
                </div>
            </div>
        </div>
    );
};

export default UnderConstruction;