import React from 'react'
import { BsInstagram } from 'react-icons/bs';
import { FaLinkedin } from 'react-icons/fa6';
import ScrollToTopButton from './ScrollToTop';

const Footer = () => {
    return (
        <footer id="footer">

            <div id="bot-footer">
                <div className="container container-extended">
                    <div className="inner-content">
                        <div className="row align-items-center">
                            <div className="col text-center text-lg-left order-3 order-lg-0">
                                <div className="copyright">
                                    &copy; {(new Date().getFullYear())} Private Deal. All Rights Reserved
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 text-center text-lg-right">
                                <ul className="social-media-links">
                                    <li>
                                        <a href="https://www.linkedin.com/company/private-deal-room/" target="_blank" rel="noopener noreferrer" className="scm-link">
                                            <FaLinkedin />
                                        </a>
                                    </li>
                                    {/* <li>
                                        <a href="https://www.facebook.com/pages/Gust/246116808757618" target="_blank" rel="noopener noreferrer" className="scm-link">
                                            <FaFacebook />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/gustly" target="_blank" rel="noopener noreferrer" className="scm-link">
                                            <FaTwitter/>
                                        </a>
                                    </li> */}
                                    <li>
                                        <a href="https://www.instagram.com/privatedealroom/" target="_blank" rel="noopener noreferrer" className="scm-link">
                                            <BsInstagram />
                                        </a>
                                    </li>
                                    {/* <li>
                                        <a href="https://twitter.com/gustly" target="_blank" rel="noopener noreferrer" className="scm-link">
                                            <FaYoutube/>
                                        </a>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ScrollToTopButton />
        </footer>
    );
}


export default Footer;
