import React, { useState, useEffect } from 'react';
import SweetAlert2 from 'react-sweetalert2';
import { TailSpin as Loader } from 'react-loader-spinner';
import MetaTags from './layouts/MetaTags';

const ContactForm = () => {

	useEffect(() => {
		// document.title = 'Book A Demo | Private Deal Room';
		window.scrollTo(0, 0); // 
	}, []);
	const [swalProps, setSwalProps] = useState({});
	const [formData, setFormData] = useState({
		name: '',
		organization: '',
		investor: false,
		startup: false,
		description: '',
		mobile: '',
		email: ''
	});
	const [submitting, setSubmitting] = useState(false);

	const handleChange = (e) => {
		const { name, value, type, checked } = e.target;
		setFormData(prevState => ({
			...prevState,
			[name]: type === 'checkbox' ? checked : value,
		}));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setSubmitting(true);

		try {
			const response = await fetch('https://www.shuruup.com/api/pdr-contact-form', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(formData)
			});

			if (!response.ok) {
				throw new Error('Network response was not ok');
			}

			//   const responseData = await response.json();
			setSwalProps({
				show: true,
				title: 'Thank You!',
				text: 'Our team will be in touch with you shortly...',
				timer: 5000, // 5 seconds
				timerProgressBar: true,
				showConfirmButton: false
			});
			// console.log('Server Response:', responseData);
			// console.log('New Post ID:', responseData.id);

			setFormData({
				name: '',
				organization: '',
				investor: false,
				startup: false,
				description: '',
				mobile: '',
				email: ''
			});
		} catch (error) {
			console.error('Error submitting form:', error.message);
		} finally {
			setSubmitting(false);
		}
	};

	return (

		<>
			<MetaTags page="/inquiry" />
			<SweetAlert2 {...swalProps} />


			<div className="container-fluid" style={{
				backgroundImage: "url('assets/contactus.svg')", backgroundPosition: "center", backgroundSize: "100% 100%"
				, minHeight: "100vh", backgroundColor: "#ffffff", padding: "50px 0", overflow: 'hidden'
			}}>
				<div>
					<h1 className="text-center mb-4">Book a demo</h1>
					<p className="text-center mb-4 fs-5 text-muted">Ready to take your investment journey to the next level? <br />Connect with us now through our contact form!</p>
					<div className="row justify-content-center align-items-center">
						<div className="col-lg-6">
							<form onSubmit={handleSubmit} style={{ backgroundColor: "#ffffff", padding: "20px", borderRadius: "10px", justifyContent: 'start', margin: "10%", marginTop: '6%', boxShadow: '0 0 45px rgba(0,0,0,0.3)' }}>
								{/* <h2 className="text-center mb-4"></h2> */}
								<div className="row mb-3">
									<label htmlFor="name" className="form-label col-md-4" style={{ fontWeight: "bold", color: "#333" }}>Name <span style={{ color: "#FF0000" }}>*</span></label>
									<div className="col-md-8">
										<input type="text" className="form-control" id="name" name="name" placeholder="Enter name" value={formData.name} onChange={handleChange} style={{ backgroundColor: "#f2f2f2" }} required />
									</div>
								</div>
								<div className="row mb-3">
									<label htmlFor="organization" className="form-label col-md-4" style={{ fontWeight: "bold", color: "#333" }}>Organization <span style={{ color: "#FF0000" }}>*</span></label>
									<div className="col-md-8">
										<input type="text" className="form-control" id="organization" name="organization" placeholder="Enter organization name" value={formData.organization} onChange={handleChange} style={{ backgroundColor: "#f2f2f2" }} required />
									</div>
								</div>
								<div className="row mb-3">
									<label className="form-label col-md-4" style={{ fontWeight: "bold", color: "#333" }}>Who Are You?<span style={{ color: "#FF0000" }}>*</span></label>
									<div className="col-md-8">
										<div className="form-check form-check-inline">
											<input type="radio" className="form-check-input" id="investor" name="role" value="investor" checked={formData.role === 'investor'} onChange={handleChange} required />
											<label className="form-check-label" htmlFor="investor" style={{ fontWeight: "bold", color: "#333" }}>Investor </label>
										</div>
										<div className="form-check form-check-inline">
											<input type="radio" className="form-check-input" id="startup" name="role" value="startup" checked={formData.role === 'startup'} onChange={handleChange} required />
											<label className="form-check-label" htmlFor="startup" style={{ fontWeight: "bold", color: "#333" }}>Startup </label>
										</div>
									</div>
								</div>
								<div className="row mb-3">
									<label htmlFor="mobile" className="form-label col-md-4" style={{ fontWeight: "bold", color: "#333" }}>Mobile Number <span style={{ color: "#FF0000" }}>*</span></label>
									<div className="col-md-8">
										<input type="number" className="form-control" id="mobile" name="mobile" placeholder="Enter mobile number" value={formData.mobile} onChange={handleChange} style={{ backgroundColor: "#f2f2f2" }} required />
									</div>
								</div>
								<div className="row mb-3">
									<label htmlFor="email" className="form-label col-md-4" style={{ fontWeight: "bold", color: "#333" }}>Email <span style={{ color: "#FF0000" }}>*</span></label>
									<div className="col-md-8">
										<input type="email" className="form-control" id="email" name="email" placeholder="Enter email" value={formData.email} onChange={handleChange} style={{ backgroundColor: "#f2f2f2" }} required />
									</div>
								</div>
								<div className="row mb-3">
									<label htmlFor="description" className="form-label col-md-4" style={{ fontWeight: "bold", color: "#333" }}>Description <span style={{ color: "#FF0000" }}>*</span></label>
									<div className="col-md-8">
										<textarea className="form-control" id="description" name="description" rows="3" placeholder="Enter description" value={formData.description} onChange={handleChange} style={{ backgroundColor: "#f2f2f2" }} required></textarea>
									</div>
								</div>
								<div className="row mb-3">
									<div className="col-md-4"></div>
									<div className="col-md-8" style={{ textAlign: "right" }}>
										{/* <button type="submit" className="btn btn-primary float-end pl-3 pr-3 rounded-5 bg-dark mt-3">Submit</button> */}
										<button type="submit" className="btn btn-primary float-end pl-3 pr-3 rounded-5 bg-dark mt-3" disabled={submitting}>
											{submitting ? <><span className="visually-hidden"></span><Loader type="TailSpin" color="#fff" height={20} width={20} /></> : "Submit"}</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</>

	);
};

export default ContactForm;
