import React from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

let ClientSlider = () => {
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 4,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2,
            slidesToSlide: 1 // optional, default to 1.
        }
    };
    return (
        <div className="container container-extended client-slider">
            <div className="row">
                <div className="col">
                    <div className="slides">
                        <Carousel responsive={responsive} infinite={true} swipeable={true} draggable={false} arrows={false} autoPlay={true} autoPlaySpeed={1500}>
                            <div className='logo-slide'>
                                <img src="assets/images/clients/rogue_logo.png" className="img-fluid" alt="Logo" />
                            </div>
                            <div className='logo-slide'>
                                <img src="assets/images/clients/enlighten_logo.png" className="img-fluid" alt="Logo" />
                            </div>
                            <div className='logo-slide'>
                                <img src="assets/images/clients/antrapreneur_logo.png" className="img-fluid" alt="Logo" />
                            </div>
                            <div className='logo-slide'>
                                <img src="assets/images/clients/ankpal_logo.png" className="img-fluid" alt="Logo" />
                            </div>
                            <div className='logo-slide'>
                                <img src="assets/images/clients/a4x_logo.png" className="img-fluid" alt="Logo" />
                            </div>
                            <div className='logo-slide'>
                                <img src="assets/images/clients/gvfl_logo.png" className="img-fluid" alt="Logo" />
                            </div>
                            <div className='logo-slide'>
                                <img src="assets/images/clients/footrax_logo.png" className="img-fluid" alt="Logo" />
                            </div>
                            <div className='logo-slide'>
                                <img src="assets/images/clients/i-hub-logo.png" className="img-fluid" alt="Logo" />
                            </div>
                            <div className='logo-slide'>
                                <img src="assets/images/clients/conclave-logo.png" className="img-fluid" alt="Logo" />
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default ClientSlider;