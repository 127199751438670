import React, { useState } from 'react'
import { Link } from 'react-router-dom';

let TabView = () => {
    const [activeTab, setActiveTab] = useState(1); // State to track active tab

    const handleTabClick = (tabNumber, e) => {
        setActiveTab(tabNumber);
        e.preventDefault();
    };

    return (
        <div className="solution-section accent-1">
            <div className="container container-extended">
                <div className="row align-items-center">
                    <div className="col-12 col-lg-6 col-xl-5 first-col">
                        <div className="content-wrapper">
                            <h2 className="section-heading"><span>Private</span> Deal <br />Room For Startup</h2>
                            <div className="solution-highlights-wrapper">
                                <div className="highlights-list">
                                    <ul className="nav flex-column nav-pills" id="accTabs" role="tablist" aria-orientation="vertical">
                                        <li className="nav-item">
                                            <a onClick={(e) => handleTabClick(1, e)} className={`item nav-link pill-link ${activeTab === 1 ? 'active' : ''}`} href={() => false} data-toggle="tab" id="pills-build-your-profile" aria-controls="build-your-profile" aria-expanded="true">
                                                <div className="icon-wrapper">
                                                    <img alt="Build Your Profile" data-src className="icon-img lazyload" src="assets/images/icons/section3-icon1.svg" />
                                                </div>
                                                <div className="details-wrapper">
                                                    <h3 className="heading">Seamless Digital Investment Process</h3>
                                                    <div className="desc default-content">
                                                        <p>Seamless Digital Investment Process: No Human Touch Needed for Completion.</p>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a onClick={(e) => handleTabClick(2, e)} className={`item nav-link pill-link ${activeTab === 2 ? 'active' : ''}`} href={() => false} data-toggle="tab" id="pills-extend-your-runway" aria-controls="extend-your-runway" aria-expanded="true">
                                                <div className="icon-wrapper">
                                                    <img alt="Extend Your Runway" data-src="assets/images/icons/Extend-your-Runway.svg" className="icon-img lazyload" src="assets/images/icons/section3-icon2.svg" />
                                                </div>
                                                <div className="details-wrapper">
                                                    <h3 className="heading">Effortless Document Management</h3>
                                                    <div className="desc default-content">
                                                        <p>
                                                            Easily issue Term Sheets, roll out SSAs, MGT-14s, Offer Letters, and more within the software during the investment process.                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a onClick={(e) => handleTabClick(3, e)} className={`item nav-link pill-link ${activeTab === 3 ? 'active' : ''}`} href={() => false} data-toggle="tab" id="pills-apply-to-incubators-and-accelerators" aria-controls="apply-to-incubators-and-accelerators" aria-expanded="true">
                                                <div className="icon-wrapper">
                                                    <img alt="Apply To Incubators And Accelerators" data-src className="icon-img lazyload" src="assets/images/icons/section3-icon3.svg" />

                                                </div>
                                                <div className="details-wrapper">
                                                    <h3 className="heading">Cap-Table Management</h3>
                                                    <div className="desc default-content">
                                                        <p>Simplify equity management with intuitive features.</p>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="btn-wrapper">
                                    <Link to="for-startup" className="black-btn btn-outline">Learn More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 col-xl-7 second-col">
                        <div className="tab-content" id="accTabs-tabContent">
                            <div className="tab-pane text-center fade show active">
                                {activeTab === 1 && <img width={2856} height={2192} alt="Build your profile" className="featured-img lazyload" src="assets/images/Section3-illustraion1.svg" />}
                                {activeTab === 2 && <img width={2856} height={2192} alt="Build your profile" className="featured-img lazyload" src="assets/images/Section3-illustraion2.svg" />}
                                {activeTab === 3 && <img width={2856} height={2192} alt="Build your profile" className="featured-img lazyload" src="assets/images/Section3-illustraion3.svg" />}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default TabView;