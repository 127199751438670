import React from 'react';
import { Helmet } from 'react-helmet';

const MetaTags = ({ page }) => {
    // console.log(page);
    const metaConfig = {
        default: {
            title: 'Home | Private Deal Room',
            description: 'Step into the future of startup investments with Private Deal Room.Seamless Funding Journeys for Startups, Angel Investors, VCs & Syndicates',
            keywords: 'StartUp, Syndicate, Merchant Banker, Private Deal Room in India, Secure Investment Software, Angel Investor, Private equity investment, Venture capital investment, Funding platform for startups,Private deal room India'
        },
        pages: {
            '/': {
                title: 'Home | Private Deal Room',
                description: 'Step into the future of startup investments with Private Deal Room.Seamless Funding Journeys for Startups, Angel Investors, VCs & Syndicates',
                keywords: 'Startup, Syndicate, Merchant Banker, Private Deal Room in India, Secure Investment Software, Angel Investor, Private equity investment, Venture capital investment, Funding platform for startups,Private deal room India'
            }, '/for-startup': {
                title: "Optimize Your Startup's Funding Journey | Private Deal Room",
                description: 'Discover a seamless digital investment flow for your startup. Effortlessly raise funds and track equity distribution with our cap-table management',
                keywords: 'Digital investment software, startup fundraising, cap-table management, private equity, investors for startups, private equity companies, angels funding, investment in startup companies, raise capital for business, fundraising for business'
            }, '/for-investor': {
                title: 'White Label Solutions for Investors | Private Deal Room',
                description: 'Digitalize your startup investments with Private Deal Room: the preferred choice of Angel Networks and Syndicates. Effortless onboarding and secured investment data.',
                keywords: 'Startup investment software, angel networks, syndicates, syndicate networks, investment in private equity, investing in startups, easy start-up onboarding, digital investment platforms, hassle-free investment documentation, secured investment data'
            }, '/about-us': {
                title: 'About Us | Private Deal Room',
                description: 'Revolutionize your investment experience with Private Deal Rooms user-friendly tools. Explore seamless compliance and streamlined processes',
                keywords: 'Equity investment, start-up investment, invest in startup companies, startup investing platforms, raise capital, venture capital, private deal rooms, investment in startup companies in India, online private deal rooms in India, best startup investment sites in India'
            }, '/inquiry': {
                title: 'Book a demo | Private Deal Room',
                description: 'Ready to invest in startup companies? Schedule pitch calls and book a demo with us now for expert guidance and support',
                keywords: 'Schedule pitch calls, book a demo, investing in startup companies, private deal inquiry, inquiry, how to raise funds for a startup?, demo inquiry, venture capital funding, how to invest in startups in India?, fundraising for business?'
            }, '/contact-us': {
                title: 'Contact Us | Private Deal Room',
                description: 'Connect with us at Private Deal Rooms corporate headquarters in Ahmedabad for technical support, feedback, or partnership inquiries. Reach out today!',
                keywords: 'Deal room contact details, private deal room contact details, private deal room, angel investors, funding platform for investors, funding for startups, digital investment software, startup funding, investors who invest in startups, investment in startup companies'
            }
        },
    };

    console.log("Page:", page);
    console.log("Page config:", metaConfig.pages[page]);
    console.log("Default config:", metaConfig.default);
    const metaData = page ? { ...metaConfig.pages[page] } : metaConfig.default; // Combine page-specific and default meta tags

    return (
        <Helmet>
            <title>{metaData.title}</title>
            <meta name="description" content={metaData.description} />
            <meta name="keywords" content={metaData.keywords} />
            <meta name="robots" content="index, follow" />
            <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
            <meta name="language" content="English" />
            <meta name="revisit-after" content="10 days" />
            <meta name="author" content="Shuru Advisory Private Limited" />
        </Helmet>
    );
};

export default MetaTags;