import React, { useEffect } from "react";
import MetaTags from "./layouts/MetaTags";

let ContactUs = () => {
    useEffect(() => {
        // document.title = 'Contact Us | Private Deal Room';
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <MetaTags page="/contact-us" />
            <div className="contact-section">
                <div className="container container-extended--xl">
                    <div className="inner-box">
                        <div className="row justify-content-center">
                            <div className="col-lg-6 mb-4 mb-xs-4 mb-sm-4 mb-md-4">
                                <iframe title="Contact Us Map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7343.97081521793!2d72.509193!3d23.024308!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7051faf7e81a69a2!2sShuru-Up!5e0!3m2!1sen!2sin!4v1674468569889!5m2!1sen!2sin" className="ft-img" style={{ border: '0' }} allowFullScreen></iframe>
                            </div>
                            <div className="col-lg-6">
                                <div className="inner-wrapper ml-lg-5">
                                    <div className="row mx-0">
                                        <h1 className="section-heading">Contact Us</h1>
                                        <div className="details-wrapper mb-4">
                                            <div className="col-12">
                                                <h3 className="heading">Corporate Headquarters</h3>
                                                <div className="desc default-content">
                                                    <p>A-1107 Mondeal Heights,&nbsp;Iscon Cross Road, Ahmedabad 380015 <a href="mailto:info@privatedealroom.in">info@privatedealroom.in</a></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mx-0">
                                        <div className="details-wrapper mb-4">
                                            <div className="col-12">
                                                <h3 className="heading">Technical Support and Feedback</h3>
                                                <div className="desc default-content">
                                                    <p className="md">For general questions and feedback,&nbsp;<a href="mailto:support@privatedealroom.in">send us a message</a>. For support requests,&nbsp;<a href="mailto:support@privatedealroom.in">please contact support</a>.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mx-0">
                                        <div className="details-wrapper mb-4">
                                            <div className="col-12">
                                                <h3 className="heading">Partnerships</h3>
                                                <div className="desc default-content">
                                                    <p>For partnership or sponsorship inquiries,&nbsp;<a href="mailto:info@privatedealroom.in">send us a message</a>.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}


export default ContactUs;