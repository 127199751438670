import React, { useState } from "react";
import { Link } from "react-router-dom";


const Header = () => {

    // State to track the mobile menu toggle
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    // Function to toggle the mobile menu
    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    return (
        <>
            <aside id="mobile-menu" className={isMobileMenuOpen ? "active" : ""}>
                <div className="mobile-menu-wrap">
                    <div className="logo-wrapper">
                        <a href="/" className="site-logo">
                            <img width={112} height={27} src="assets/images/pvt_deal_logo.png" className="site-logo-img" alt="Private Deal Logo" />
                        </a>
                    </div>
                    <div className="mobile-body">
                        <div className="menu-main-menu-left-container">
                        </div>
                        <div className="menu-main-menu-right-container">
                            <ul id="menu-main-menu-right" className="menu">
                                <li id="menu-item-279" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-279">
                                    <Link to="">Home</Link>
                                </li>
                                <li id="menu-item-37" className="header-faq menu-item menu-item-type-custom menu-item-object-custom menu-item-37">
                                    <Link to="about-us">About Us</Link>
                                </li>
                                <li id="menu-item-9326" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-9326">
                                    <Link to="contact-us">Contact Us</Link>
                                </li>
                                <li id="menu-item-9326" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-9326">
                                    <a href="#watch-a-demo">Watch Video</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </aside>
            {/* SITE HEADER */}
            <header id="header">
                <div className="main-header">
                    <div className="container container-extended--xl">
                        <div className="row-wrap header-row">
                            <div className="inner-item left">
                                <div className="logo-wrapper">
                                    <Link to="/" className="site-logo">
                                        <img width={112} height={27} src="assets/images/pvt_deal_logo.png" className="site-logo-img" alt="Private Deal Logo" />
                                    </Link>
                                </div>
                            </div>
                            <div className="inner-item center text-left">
                                <div className="menu-main-menu-left-container">
                                </div>
                            </div>
                            <div className="inner-item right text-right">
                                <div className="menu-main-menu-right-container">
                                    <ul id="menu-main-menu-right-1" className="menu">
                                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-279">
                                            <Link to="">Home</Link>
                                        </li>
                                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-279">
                                            <Link to="about-us">About Us</Link>
                                        </li>
                                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-279">
                                            <Link to="contact-us">Contact Us</Link>
                                        </li>
                                        {/* <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-279">
                                            <a to="blog/">Blog</a>
                                        </li>
                                        <li className="header-faq menu-item menu-item-type-custom menu-item-object-custom menu-item-37">
                                            <a to="launch/faq">FAQ</a>
                                        </li>
                                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-9326">
                                            <a to="pricing/">Pricing</a>
                                        </li> */}
                                        <li className="menu-btn-outline menu-item menu-item-type-custom menu-item-object-custom menu-item-39">
                                            <a href="#watch-a-demo">Watch Video</a>
                                            {/* <Link to="for-investor">Watch Video</Link> */}
                                        </li>
                                    </ul>
                                </div>
                                {/* <Link to="/" className="hamburger-icon mobile-menu-trigger">
                                    <span className="hamburger-inner" />
                                </Link> */}

                                {/* <a href="#" class="hamburger-icon mobile-menu-trigger">
                                    <span class="hamburger-inner"></span>
                                </a> */}
                                <a href="#" className={isMobileMenuOpen ? "hamburger-icon mobile-menu-trigger show-mobile-menu" : "hamburger-icon mobile-menu-trigger"} onClick={toggleMobileMenu}>
                                    <span className="hamburger-inner" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </header >
        </>
    );
}

export default Header;
