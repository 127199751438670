import './App.css';
import Header from './components/layouts/Header';
import Footer from './components/layouts/Footer';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import AboutUs from './components/AboutUs';
import ContactUs from './components/ContactUs';
import Investor from './components/Investor';
import Startups from './components/Startups';
import ContactForm from './components/ContactForm';

function App() {
	return (
		<Router>
			<Header />
			{/* <Route path="/start-your-journey" element={<StartYourJourney />} />
				<Route path="/users/sign_in" element={<Login />} />
				<Route path="/about" element={<Aboutus />} />
				<Route path="/contact" element={<Contactus />} /> */}
			<main id="main-wrapper">
				<Routes>
					{/* <Route path="/contact" element={<Contactus />} /> */}
					<Route path="/contact-us" element={<ContactUs />} />
					<Route path="/for-investor" element={<Investor />} />
					<Route path="/for-startup" element={<Startups />} />
					<Route path="/about-us" element={<AboutUs />} />
					<Route path="/" element={<Home />} />
					<Route path="/book-a-demo" element={<ContactForm />} />
				</Routes>
			</main>
			<Footer />
		</Router>
	);
}

export default App;
