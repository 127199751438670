import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import MetaTags from "./layouts/MetaTags";


let About = () => {
    useEffect(() => {
        // document.title = 'About Us | Private Deal Room';
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <MetaTags page="/about-us" />
            <div className="hero-banner">
                <div className="container container-extended--xxl">
                    <div className="hero-banner--inner-wrapper">
                        <div className="row align-items-center">
                            <div className="col-12 col-lg-6">
                                <div className="hero-banner--content-wrap">
                                    <h1 className="section-heading" style={{ fontSize: '3.5rem' }} >About Us</h1>

                                    <div className="desc default-content" style={{ fontSize: '1.5rem',textAlign:"justify" }}>Dedicated to driving innovation in startup investments, we utilise advanced technological solutions to reshape the landscape. Our software simplifies equity investment processes, guaranteeing compliance and efficiency. We equip investors and entrepreneurs with user-friendly tools, enhancing accessibility in startup investments. Discover leading startup investment platforms with us.<br />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="hero-banner--ft-img-wrapper text-lg-right">
                                    <img width={881} height={896} alt="About Us Banner Bg" className="ft-img lazyload" src="assets/images/about-us-banner-bg.png" style={{ width: '700px', height: '700px' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div div className="cta-box-section" >
                <div className="container container-extended">
                    <div className="inner-box">
                        <h2 className="section-heading">Your moment to advance begins!</h2>
                        <div className="btn-wrapper">
                            <Link to="/book-a-demo" className="white-btn">Get Started</Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}


export default About;