import React, { useState, useEffect } from 'react';
import constructionEndTime from "../../../index";
const LaunchingSoonCountDown = () => {
    const [countdown, setCountdown] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });

    useEffect(() => {
        const launchDate = constructionEndTime;
        const interval = setInterval(() => {
            const now = new Date().getTime();
            const distance = launchDate - now;

            const days = Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);

            setCountdown({ days, hours, minutes, seconds });

            if (distance < 0) {
                clearInterval(interval);
                setCountdown({ days: 0, hours: 0, minutes: 0, seconds: 0 });
            }
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div>
            <h6 className='text-center'>Launching in {countdown.days !== 0 ? countdown.days + ' days, ' : ''} {countdown.hours !== 0 ? countdown.hours + ' hours, ' : ''} {countdown.minutes !== 0 ? countdown.minutes + ' minutes, and ' : ''}  {countdown.seconds} seconds.</h6>
        </div>
    );
};

export default LaunchingSoonCountDown;
