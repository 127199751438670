
import React from 'react';
import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';

let VideoView = () => {
    return (
        <div className="service-cards-section plain" id="watch-a-demo">
            <div className="container container-extended">
                <div className="ss-section-title">
                    <h2 className="section-heading text-center">Introduction Video</h2>
                </div>
                <div className="ss-section-description text-center mb-5">
                </div>
                <div className="demo-video-section">
                    <div className="inner-box mt-4">
                        <div style={{ width: '100%', margin: '0 auto', borderRadius: '1.875rem', overflow: 'hidden', border: '1px solid #ccc' }}>
                            <Video
                                poster='assets/thumbnail.png'
                                style={{ width: '100%', borderRadius: '1.875rem' }}>
                                <source src='assets/video-a.mp4' type='video/webm' />
                            </Video>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default VideoView;