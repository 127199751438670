import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import MetaTags from "./layouts/MetaTags";

let Investor = () => {
    useEffect(() => {
        // document.title = 'For Investors | Private Deal Room';
        window.scrollTo(0, 0); // 
    }, []);
    return (
        <>
            <MetaTags page="/for-investor" />
            <div className="page-template-template-aca-second-version">
                <div className="hero-banner">
                    <div className="container container-extended--xxl">
                        <div className="hero-banner--inner-wrapper">
                            <div className="row align-items-center">
                                <div className="col-12 col-lg-7">
                                    <div className="hero-banner--content-wrap">
                                        <h1 className="section-heading">
                                            A Software to Digitize Your Startup Investment
                                        </h1>
                                        <div className="btn-wrapper">

                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-5">
                                    <div className="hero-banner--ft-img-wrapper text-lg-right">
                                        <img
                                            src="assets/images/investors-illustration.svg"
                                            alt="Aca 2023 Hero Bg"
                                            width={704}
                                            height={702}
                                            className="ft-img lazyload"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="service-cards-section plain">
                    <div className="container container-extended">
                        <div className="ss-section-title">
                            <h2 className="section-heading text-center">
                                Used by Angel Networks and Syndicates.
                            </h2>
                        </div>
                        <div className="ss-section-description text-center mb-5">
                            Private Deal Room serves as a cornerstone for facilitating collaboration between investor organisations and promising startups. It's the favoured option among well known entities in the Angel Investment industry. It helps in efficiently managing deal flow, pipeline, and transitioning seamlessly into the digital investment landscape.
                        </div>
                        {/* <div className="milestone-section">
                            <div className="inner-box mt-4">
                                <div className="year">In 2022 </div>
                                <div className="row mt-3">
                                    <div className="col">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="value">27k </div>
                                                <div className="title">Applications</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="value">350+ </div>
                                                <div className="title">Groups</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="value">44k </div>
                                                <div className="title">Investors</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="value">197 </div>
                                                <div className="title">Countries</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="value">$170MM </div>
                                                <div className="title">Invested</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="service-cards-section solutions-cards-section plain">
                    <div className="container container-extended">
                        <div className="ss-section-title">
                            <h2 className="section-heading text-center">
                                A Comprehensive Solution
                            </h2>
                        </div>
                        {/* <div className="ss-section-description text-center">
                            All the features you need to manage your group’s deal intake,
                            screening, and diligence.{" "}
                        </div> */}
                        <div className="solutions-list services-list mt-4">
                            <div className="item">
                                <div className="service-card solutions-card">
                                    <img
                                        src="assets/images/icons/receive_applications.svg"
                                        alt="Receive Applications"
                                        width={86}
                                        height={86}
                                        className="service-img solutions-img lazyload"
                                    />
                                    <h3 className="service-name solutions-name">
                                        Receive Applications
                                    </h3>
                                    <div className="description default-content">
                                        <p>
                                            Filtered by
                                            Investment Preferences or invite founders from your
                                            network to apply.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="service-card solutions-card">
                                    <img
                                        src="assets/images/icons/easy_startup_onboarding.svg"
                                        alt="Rate And Review"
                                        width={86}
                                        height={86}
                                        className="service-img solutions-img lazyload"
                                    />
                                    <h3 className="service-name solutions-name">
                                        Easy Start-Up On-Boarding
                                    </h3>
                                    <div className="description default-content">
                                        <p>
                                            From Sign-Up to Investment Ready: Just a Click Away!
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="service-card solutions-card">
                                    <img
                                        src="assets/images/icons/digital_Investment_flow.svg"
                                        alt="Manage Group"
                                        width={86}
                                        height={84}
                                        className="service-img solutions-img lazyload"
                                    />
                                    <h3 className="service-name solutions-name">
                                        Digital Investment Flow
                                    </h3>
                                    <div className="description default-content">
                                        <p>
                                            Seamless Digital Investment: No Human Touch Needed for Completion.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="service-card solutions-card">
                                    <img
                                        src="assets/images/icons/shedule_pitch_calls.svg"
                                        alt="Data Secure"
                                        width={86}
                                        height={86}
                                        className="service-img solutions-img lazyload"
                                    />
                                    <h3 className="service-name solutions-name">
                                        Schedule Pitch Calls
                                    </h3>
                                    <div className="description default-content">
                                        <p>
                                            Connect One-on-One with Founders within your software.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="service-card solutions-card">
                                    <img
                                        src="assets/images/icons/manage_your_own_angel_network.svg"
                                        alt="Schedule Events"
                                        width={83}
                                        height={86}
                                        className="service-img solutions-img lazyload"
                                    />
                                    <h3 className="service-name solutions-name">
                                        Manage Your Own Angel Network
                                    </h3>
                                    <div className="description default-content">
                                        <p>
                                            Onboard Investors, Invest Together: Collaborate Seamlessly Within your software.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="item">
                                <div className="service-card solutions-card">
                                    <img
                                        src="assets/images/icons/track_your_post_investment.svg"
                                        alt="Existing Tools"
                                        width={86}
                                        height={86}
                                        className="service-img solutions-img lazyload"
                                    />
                                    <h3 className="service-name solutions-name">
                                        Track Your Post- Investment
                                    </h3>
                                    <div className="description default-content">
                                        <p>
                                            Stay Up-to-Date with MIS Feature on Post-Investment Matters.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="service-card solutions-card">
                                    <img
                                        src="assets/images/icons/Customized_as_per_existing_theme.svg"
                                        alt="Deal Flow"
                                        width={86}
                                        height={86}
                                        className="service-img solutions-img lazyload"
                                    />
                                    <h3 className="service-name solutions-name">
                                        Customized as Per Existing Theme
                                    </h3>
                                    <div className="description default-content">
                                        <p>
                                            Tailor Your software to Reflect Your Brand’s Unique Identity.{" "}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="service-card solutions-card">
                                    <img
                                        src="assets/images/icons/realtime-notification.svg"
                                        alt="Track And Record"
                                        width={86}
                                        height={86}
                                        className="service-img solutions-img lazyload"
                                    />
                                    <h3 className="service-name solutions-name">
                                        Realtime Notification
                                    </h3>
                                    <div className="description default-content">
                                        <p>
                                            Real-Time Monitoring: Effortlessly Track Your Investment Process.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="service-card solutions-card">
                                    <img
                                        src="assets/images/icons/hassle-free_documentation.svg"
                                        alt="Track And Record"
                                        width={86}
                                        height={86}
                                        className="service-img solutions-img lazyload"
                                    />
                                    <h3 className="service-name solutions-name">
                                        Hassle-Free Documentation
                                    </h3>
                                    <div className="description default-content">
                                        <p>
                                            Seamlessly Roll Out Agreements and Safeguard Your Documents in Our Hassle-Free Documentation Within the software.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="service-cards-section plain">
                    <div className="container container-extended">
                        <div className="ss-section-title">
                            <h2 className="section-heading text-center">
                                Simple Setup. Easy to Use.
                            </h2>
                        </div>
                        {/* <div className="ss-section-description text-center">
                            Gust is simple to set up, easy to use, and supported by
                            knowledgeable, friendly staff. There are no limits on group
                            membership or file storage and all customers receive an
                            industry-leading service level.{" "}
                        </div> */}
                        <div className="services-list mt-4">
                            <div className="item">
                                <div className="service-card">
                                    <img
                                        src="assets/images/icons/effortless-setup.svg"
                                        alt="Simple Setup"
                                        width={72}
                                        height={86}
                                        className="service-img lazyload"
                                    />
                                    <h3 className="service-name">Effortless Setup</h3>
                                    <div className="description default-content">
                                        <p>
                                            Private Deal Room provides tools and assistance to ensure the quick and easy import of existing data and setup of your personalised software.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="service-card">
                                    <img
                                        src="assets/images/icons/easy-to-use-icon.svg"
                                        alt="Easy To Use"
                                        width={86}
                                        height={86}
                                        className="service-img lazyload"
                                    />
                                    <h3 className="service-name">Easy to Use</h3>
                                    <div className="description default-content">
                                        <p>
                                            Private Deal Room software is designed to work seamlessly with your current deal flow management processes. It is user friendly, intuitive and customizable enabling you to work more efficiently.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="service-card">
                                    <img
                                        src="assets/images/icons/dedicated-support.svg"
                                        alt="Dedicated Support"
                                        width={86}
                                        height={86}
                                        className="service-img lazyload"
                                    />
                                    <h3 className="service-name">Tailored Assistance</h3>
                                    <div className="description default-content">
                                        <p>
                                            Get advantage of our detailed help guides and dedicated support via chat and email. Access the resources and assistance needed for smooth startup fundraising operations.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="logo-section">
                    <div className="container container-extended">
                        <div className="ss-section-title mb-4">
                            <h2 className="section-heading text-center">
                                Leverage a powerful ecosystem
                            </h2>
                            <div className="lp-section-description text-center">
                                Private Deal Room proudly aligns with angel networks and early-stage capital organisations. Whether you're optimising your workflow or establishing a new investor organisation, our experienced team is here to provide guidance every step of the way.<br />
                                <br />
                                Our software and ecosystem cater to founders, family offices, incubators, accelerators, angel groups, venture funds, and other early-stage force multipliers. With our end-to-end digital platform, we strive to streamline investment management within your ecosystem, ensuring accessibility and efficiency for your organisation.
                            </div>
                        </div>
                        {/* <div className="milestone-section">
                            <div className="inner-box mt-4 mb-4">
                                <div className="row mt-3">
                                    <div className="col">
                                        <hr />
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="title">Better Startups</div>
                                                <div className="value">
                                                    Gust helps more than 10k startups every month by
                                                    providing education and tools to level set
                                                    expectations, connect with the right support
                                                    resources, and manage their operations. Groups on Gust
                                                    get startups that are better prepared, pre-vetted, and
                                                    require less diligence.{" "}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <hr />
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="title">Collaborative Capital</div>
                                                <div className="value">
                                                    Syndicate with other Angels to tag along or fill out
                                                    deals. Make connections with upstream and downstream
                                                    capital allocators to find new sources of deal flow
                                                    and new partners for follow-on rounds.{" "}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <hr />
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="title">Learn from the best</div>
                                                <div className="value">
                                                    Benefit from knowledge and best practices developed
                                                    over nearly two decades of supporting the world’s
                                                    largest angel groups and early stage capital
                                                    providers. Whether you’re looking to streamline
                                                    operations or bootstrap a new group, Gust can guide
                                                    the way.{" "}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="inner-box">
                            <ul className="logo-list">
                                <li>
                                    <img
                                        src="https://gust.com/wp-content/uploads/2023/05/new-york-angels.png"
                                        alt="New York Angels"
                                        width={45}
                                        height={45}
                                        className="logo-img lazyload"
                                    />
                                </li>
                                <li>
                                    <img
                                        src="https://gust.com/wp-content/uploads/2023/05/oregon.png"
                                        alt="Oregon"
                                        width={98}
                                        height={36}
                                        className="logo-img lazyload"
                                    />
                                </li>
                                <li>
                                    <img
                                        src="https://gust.com/wp-content/uploads/2023/05/tonic.png"
                                        alt="Tonic"
                                        width={88}
                                        height={39}
                                        className="logo-img lazyload"
                                    />
                                </li>
                                <li>
                                    <img
                                        src="https://gust.com/wp-content/uploads/2023/05/robinhood.png"
                                        alt="Robinhood"
                                        width={113}
                                        height={35}
                                        className="logo-img lazyload"
                                    />
                                </li>
                                <li>
                                    <img
                                        src="https://gust.com/wp-content/uploads/2023/05/park-city.png"
                                        alt="Park City"
                                        width={113}
                                        height={58}
                                        className="logo-img lazyload"
                                    />
                                </li>
                                <li>
                                    <img
                                        src="https://gust.com/wp-content/uploads/2023/05/westchester-190x25.png"
                                        alt="Westchester"
                                        width={190}
                                        height={25}
                                        className="logo-img lazyload"
                                    />
                                </li>
                                <li>
                                    <img
                                        src="https://gust.com/wp-content/uploads/2023/05/angels.png"
                                        alt="Angels"
                                        width={71}
                                        height={43}
                                        className="logo-img lazyload"
                                    />
                                </li>
                                <li>
                                    <img
                                        src="https://gust.com/wp-content/uploads/2023/05/harvard.png"
                                        alt="Harvard"
                                        width={146}
                                        height={34}
                                        className="logo-img lazyload"
                                    />
                                </li>
                                <li>
                                    <img
                                        src="https://gust.com/wp-content/uploads/2023/05/nextfab.png"
                                        alt="Nextfab"
                                        width={89}
                                        height={34}
                                        className="logo-img lazyload"
                                    />
                                </li>
                                <li>
                                    <img
                                        src="https://gust.com/wp-content/uploads/2023/05/traction.png"
                                        alt="Traction"
                                        width={111}
                                        height={25}
                                        className="logo-img lazyload"
                                    />
                                </li>
                                <li>
                                    <img
                                        src="https://gust.com/wp-content/uploads/2023/05/mossy.png"
                                        alt="Mossy"
                                        width={107}
                                        height={50}
                                        className="logo-img lazyload"
                                    />
                                </li>
                                <li>
                                    <img
                                        src="https://gust.com/wp-content/uploads/2023/05/conference.png"
                                        alt="Conference"
                                        width={126}
                                        height={53}
                                        className="logo-img lazyload"
                                    />
                                </li>
                                <li>
                                    <img
                                        src="https://gust.com/wp-content/uploads/2023/05/commune.png"
                                        alt="Commune"
                                        width={122}
                                        height={25}
                                        className="logo-img lazyload"
                                    />
                                </li>
                                <li>
                                    <img
                                        src="https://gust.com/wp-content/uploads/2023/05/chemical.png"
                                        alt="Chemical"
                                        width={142}
                                        height={41}
                                        className="logo-img lazyload"
                                    />
                                </li>
                                <li>
                                    <img
                                        src="https://gust.com/wp-content/uploads/2023/05/globe.png"
                                        alt="Globe"
                                        width={63}
                                        height={54}
                                        className="logo-img lazyload"
                                    />
                                </li>
                                <li>
                                    <img
                                        src="https://gust.com/wp-content/uploads/2023/05/maine.png"
                                        alt="Maine"
                                        width={88}
                                        height={50}
                                        className="logo-img lazyload"
                                    />
                                </li>
                                <li>
                                    <img
                                        src="https://gust.com/wp-content/uploads/2023/05/hydepark.png"
                                        alt="Hydepark"
                                        width={141}
                                        height={43}
                                        className="logo-img lazyload"
                                    />
                                </li>
                                <li>
                                    <img
                                        src="https://gust.com/wp-content/uploads/2023/05/a2a.png"
                                        alt="A2a"
                                        width={96}
                                        height={57}
                                        className="logo-img lazyload"
                                    />
                                </li>
                                <li>
                                    <img
                                        src="https://gust.com/wp-content/uploads/2023/05/the-mangnus.png"
                                        alt="The Mangnus"
                                        width={150}
                                        height={38}
                                        className="logo-img lazyload"
                                    />
                                </li>
                                <li>
                                    <img
                                        src="https://gust.com/wp-content/uploads/2023/05/sputnik.png"
                                        alt="Sputnik"
                                        width={64}
                                        height={64}
                                        className="logo-img lazyload"
                                    />
                                </li>
                                <li>
                                    <img
                                        src="https://gust.com/wp-content/uploads/2023/05/vertical.png"
                                        alt="Vertical"
                                        width={62}
                                        height={73}
                                        className="logo-img lazyload"
                                    />
                                </li>
                                <li>
                                    <img
                                        src="https://gust.com/wp-content/uploads/2023/05/morgan.png"
                                        alt="Morgan"
                                        width={146}
                                        height={23}
                                        className="logo-img lazyload"
                                    />
                                </li>
                                <li>
                                    <img
                                        src="https://gust.com/wp-content/uploads/2023/05/dlph.png"
                                        alt="Dlph"
                                        width={67}
                                        height={42}
                                        className="logo-img lazyload"
                                    />
                                </li>
                                <li>
                                    <img
                                        src="https://gust.com/wp-content/uploads/2023/05/mintz.png"
                                        alt="Mintz"
                                        width={42}
                                        height={51}
                                        className="logo-img lazyload"
                                    />
                                </li>
                                <li>
                                    <img
                                        src="https://gust.com/wp-content/uploads/2023/05/corex.png"
                                        alt="Corex"
                                        width={107}
                                        height={36}
                                        className="logo-img lazyload"
                                    />
                                </li>
                                <li>
                                    <img
                                        src="https://gust.com/wp-content/uploads/2023/05/awsactivate.png"
                                        alt="Awsactivate"
                                        width={126}
                                        height={18}
                                        className="logo-img lazyload"
                                    />
                                </li>
                                <li>
                                    <img
                                        src="https://gust.com/wp-content/uploads/2023/05/brex.png"
                                        alt="Brex"
                                        width={86}
                                        height={23}
                                        className="logo-img lazyload"
                                    />
                                </li>
                                <li>
                                    <img
                                        src="https://gust.com/wp-content/uploads/2023/05/capchase.png"
                                        alt="Capchase"
                                        width={113}
                                        height={19}
                                        className="logo-img lazyload"
                                    />
                                </li>
                                <li>
                                    <img
                                        width={113}
                                        height={29}
                                        alt="Forecastr"
                                        data-src="https://gust.com/wp-content/uploads/2023/05/forecastr.png"
                                        className="logo-img lazyload"
                                        src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                    />
                                </li>
                                <li>
                                    <img
                                        src="https://gust.com/wp-content/uploads/2023/05/hubspot.png"
                                        alt="Hubspot"
                                        width={85}
                                        height={61}
                                        className="logo-img lazyload"
                                    />
                                </li>
                            </ul>
                        </div> */}
                    </div>
                </div>
                {/* <div className="service-cards-section plain" id="watch-a-demo">
                    <div className="container container-extended">
                        <div className="ss-section-title">
                            <h2 className="section-heading text-center">Watch a Demo</h2>
                        </div>
                        <div className="ss-section-description text-center mb-5"></div>
                        <div className="demo-video-section">
                            <div className="inner-box mt-4"></div>
                        </div>
                    </div>
                </div> */}
                <div className="cta-box-section">
                    <div className="container container-extended">
                        <div className="inner-box">
                            <div className="ss-section-title">
                                <h2 className="section-heading">
                                    Ready to Venture Forward with Private Deal Room?
                                </h2>
                                <div className="cta-section-description text-center"></div>
                            </div>
                            <div className="btn-wrapper">
                                <Link to="/book-a-demo" className="black-btn btn-outline">
                                    GET STARTED
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}


export default Investor;