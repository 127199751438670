// import React from "react";
import React, { useEffect } from 'react';
import ClientSlider from "./layouts/functions/ClientSlider";
import HomeTabViewForInvestor from "./layouts/functions/HomeTabViewForInvestor";
import HomeTabViewForStartup from "./layouts/functions/HomeTabViewForStartup";
import HomeSlider from "./layouts/functions/HomeSlider";
import Video from "./layouts/functions/Video";
import { Link } from 'react-router-dom';
import MetaTags from './layouts/MetaTags';

let Home = () => {
    useEffect(() => {
        // document.title = 'Home | Private Deal Room';
        document.body.classList.add('page-template-template-product');
    }, []);
    return (
        <>
            <MetaTags page="/" />
            <div className="hero-banner">
                <div className="container container-extended--xxl">

                    <HomeSlider />

                </div>
            </div >
            <div className="landing-main-sec partner-landing-main-sec">
                {/* <div className="container container-extended"> */}
                <div className="inner-cont-wrap">
                    <div className="cont-wrap">
                        <h1 className="section-heading">If you are
                            <div className="words">
                                <span>A Start-Up...</span>
                                <span>An Angel Network...</span>
                                <span>A Syndicate...</span>
                                <span>A Merchant Banker...</span>
                                <span>A Charted Accountant...</span>
                                <span>A Family Offices...</span>
                                <span>A VC Fund...</span>
                            </div> then Private Deal Room is for you</h1>
                    </div>
                </div>
                {/* </div> */}
            </div>
            <div className="product-section">
                <div className="container container-extended">
                    <div className="products-boxes">
                        <div className="item">
                            <div className="product-wrapper">
                                <div className="thumbnail-wrapper">
                                    <img width={240} height={270} alt="Startups" className="thumbnail lazyload" src="assets/images/icons/section4-icon1.svg" />
                                </div>
                                <div className="details-wrapper">
                                    <h3 className="heading">For Start-Up</h3>
                                    <div className="brief-desc default-content">
                                        <p>Access Private Deal Room with management tools on a secure software.</p>
                                    </div>
                                    <div className="btn-wrapper">
                                        <Link to="/for-startup" className="black-btn btn-outline">Learn More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="product-wrapper">
                                <div className="thumbnail-wrapper">
                                    <img width={153} height={270} alt="Investors" className="thumbnail lazyload" src="assets/images/icons/section4-icon2.svg" />
                                </div>
                                <div className="details-wrapper">
                                    <h3 className="heading">For Angel Network/ Syndicate</h3>
                                    <div className="brief-desc default-content">
                                        <p>Build your eco-system and make your cohorts happy.</p>
                                    </div>
                                    <div className="btn-wrapper">
                                        <Link to="/for-investor" className="black-btn btn-outline">Learn More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="leverage-section">
                <div className="container container-extended--xxl">
                    <div className="inner-box">
                        <div className="timeline mt-3">
                            <div className="row desc-container">
                                <div className="tl-item col-md-12 col-lg-3 col-xl-3">
                                    <div className="tl-icon-holder text-center mt-2 mb-2">

                                        <img width={100} height={64} alt="Community Founders" data-src className="tl-icon lazyload" src="assets/images/icons/section2-icon1.svg" />

                                    </div>
                                    <div className="tl-content text-center mt-2 mb-3" style={{ color: '#4D4D4D !important' }}>
                                        Secure Your Demo: Tailored Solutions Await.
                                    </div>
                                </div>
                                <div className="tl-item col-md-12 col-lg-3 col-xl-3">
                                    <div className="tl-icon-holder text-center mt-2 mb-2">
                                        <img width={73} height={73} alt="Powerful Partners" data-src className="tl-icon lazyload" src="assets/images/icons/section2-icon2.svg" />

                                    </div>
                                    <div className="tl-content text-center mt-2 mb-3" style={{ color: '#4D4D4D !important' }}>
                                        Punctual Delivery: Precision in software Deployment.
                                    </div>
                                </div>
                                <div className="tl-item col-md-12 col-lg-3 col-xl-3">
                                    <div className="tl-icon-holder text-center mt-2 mb-2">
                                        <img width={76} height={75} alt="Top Accelerators" data-src className="tl-icon lazyload" src="assets/images/icons/section2-icon3.svg" />

                                    </div>
                                    <div className="tl-content text-center mt-2 mb-3" style={{ color: '#4D4D4D !important' }}>
                                        Initiate Your Investment Voyage with Confidence.
                                    </div>
                                </div>
                                <div className="tl-item col-md-12 col-lg-3 col-xl-3">
                                    <div className="tl-icon-holder text-center mt-2 mb-2">
                                        <img width={66} height={67} alt="Leading Angel Investment" data-src className="tl-icon lazyload" src="assets/images/icons/section2-icon4.svg" />

                                    </div>
                                    <div className="tl-content text-center mb-3 mt-2" style={{ color: '#4D4D4D !important' }}>
                                        Attain Success: Our Proven Solution Ensures Results.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <HomeTabViewForInvestor />
            <ClientSlider />
            <HomeTabViewForStartup />



            <div className="service-cards-section">
                <div className="container container-extended">
                    <h2 className="section-heading text-center">Your Startup Support System</h2>
                    <div className="desc default-content text-center mb-3">Technology propels our vision at Private Deal Room, but it's our human element that truly defines us. Explore our SAAS for a complete entrepreneurial support system, anchored by Mission Control.</div>
                    <div className="services-list">
                        <div className="item">
                            <div className="service-card">
                                <img width={86} height={86} alt={10000} data-src className="service-img lazyload" src="assets/images/icons/section6-icon1.svg" />

                                <h3 className="service-name">Technical support</h3>
                                <div className="description default-content">
                                    <p>Our specialised team provides personalised technical support for startups, syndicates, merchant bankers, and private equity investors. With tailored solutions, responsive communication, and secure investment software, trust us as your steadfast ally in overcoming challenges and achieving success.</p>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="service-card">
                                <img width={86} height={86} alt="Easy To Use" data-src className="service-img lazyload" src="assets/images/icons/section6-icon2.svg" />

                                <h3 className="service-name">Expert Sessions</h3>
                                <div className="description default-content">
                                    <p>Our experts provide guidance for startups, syndicates, and venture capitalists, from deal structuring to finalisation. You can trust us to navigate complexities, ensuring strategic structuring and precise finalisation. Specialising in private deal rooms, we offer expertise and precision in every transaction.</p>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="service-card">
                                <img width={76} height={86} alt="Support" data-src className="service-img lazyload" src="assets/images/icons/section6-icon3.svg" />
                                <h3 className="service-name">Network</h3>
                                <div className="description default-content">
                                    <p>We excel in curating connections customized to meet specific needs effectively. Whether it involves forging partnerships, accessing mentorship, or expanding professional networks, rest assured, we navigate the network with precision and purpose, striving for success.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="testimonials-section">
                <div className="container container-extended">
                    <div className="testimonials-slider-wrapper">
                        <h2 className="section-heading">Execution speaks louder than words. <br />Let our results and client testimonials do the talking.</h2>
                        <div className="testimonials-slide row mx-0 row-eq-height">
                            <div className="col-lg-6 col-xl-4 slider-item">
                                <div className="testimonial-inner-wrapper align-items-start flex-column">
                                    <div className="testimonial-top-holder">
                                        <div className="logo-wrapper text-center">
                                            <div className="inner-logo-wrapper mt-2">
                                                <img width={176} height={55} alt="Edkonnect" data-src className="company-logo testi-thumbnail m-auto lazyload" src="assets/images/testimonials/eaf.jpeg" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="testimonial-mid-holder">
                                        <span className="quote-icon">“</span>
                                        <div className="testi-feedback">
                                            “As CEO of Enlighten Angel Funds, I highly recommend Private Deal Room. It's the ultimate software for syndicates and VCs, streamlining investor and startup management with its user-friendly interface and robust features.”
                                        </div>
                                    </div>
                                    <div className="testimonial-bot-holder">
                                        <div className="testi-name" style={{ marginRight: '20px' }}>
                                            -Koushik Sekhar, CEO
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-4 slider-item">
                                <div className="testimonial-inner-wrapper align-items-start flex-column">
                                    <div className="testimonial-top-holder">
                                        <div className="logo-wrapper text-center">
                                            <div className="inner-logo-wrapper mt-2">
                                                <img width={192} height={48} alt="Csu" className="company-logo testi-thumbnail m-auto lazyload" src="assets/images/testimonials/rogue.jpeg" />

                                            </div>
                                        </div>
                                    </div>
                                    <div className="testimonial-mid-holder">
                                        <span className="quote-icon">“</span>
                                        <div className="testi-feedback">
                                            “Private Deal Room has been incredibly helpful for me as a founder and entrepreneur. It's an essential tool in my investment journey, and I genuinely wish I had found it sooner.”
                                        </div>
                                    </div>
                                    <div className="testimonial-bot-holder">
                                        <br />
                                        <div className="testi-name" style={{ marginRight: '20px' }}>-Marmik Shah, Partner
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-4 slider-item">
                                <div className="testimonial-inner-wrapper align-items-start flex-column">
                                    <div className="testimonial-top-holder">
                                        <div className="logo-wrapper text-center">
                                            <div className="inner-logo-wrapper mt-2">
                                                <img width={144} height={53} alt="Talking To My Doctor" className="company-logo testi-thumbnail m-auto lazyload" src="assets/images/testimonials/ankpal.jpeg" />

                                            </div>
                                        </div>
                                    </div>
                                    <div className="testimonial-mid-holder">
                                        <span className="quote-icon">“</span>
                                        <div className="testi-feedback">
                                            “I highly value Private Deal Room for its effectiveness in my startup journey. It's been an integral part of managing my operations, and discovering it earlier would have been beneficial.”
                                        </div>
                                    </div>
                                    <div className="testimonial-bot-holder">
                                        <div className="testi-name" style={{ marginRight: '20px' }}>-Prasad Akhani, Founder</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Video />
            <div className="cta-box-section">
                <div className="container container-extended">
                    <div className="inner-box">
                        <h2 className="section-heading text-center">Let’s start your journey with private deal room.</h2>
                        <div className="btn-wrapper">
                            {/* <Link className="white-btn" to="/book-a-demo" target="" className="main-btn">Book A Demo Now</Link> */}
                            <Link to="/book-a-demo" className="white-btn">Book A Demo</Link>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Home;