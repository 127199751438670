import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import UnderConstruction from './components/Underconstruction';

const constructionEndTime = new Date("April 20, 2024 15:00:00").getTime();
const currentTime = new Date().getTime();
const root = ReactDOM.createRoot(document.getElementById('root'));

let element = <UnderConstruction />;

if (currentTime >= constructionEndTime) {
	element = <App />;
}

const renderElement = () => {
	root.render(
		<React.StrictMode>
			{element}
		</React.StrictMode>
	);
};

renderElement();

// Check every second if current time is after construction end time
const intervalId = setInterval(() => {
	if (new Date().getTime() >= constructionEndTime) {
		clearInterval(intervalId); // Stop checking once construction end time has passed
		element = <App />; // Update the element to App component
		renderElement(); // Render the updated element
	}
}, 1000);

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export default constructionEndTime;
